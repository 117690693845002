/*eslint-disable*/
import React from "react"
// react components for routing our app without refresh
import { Link } from "gatsby"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Tooltip from "@material-ui/core/Tooltip"

// @material-ui/icons
import { Apps, CloudDownload } from "@material-ui/icons"

// React icons
import { FaTwitter, FaFacebook, FaInstagram } from "react-icons/fa"

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx"
import Button from "components/CustomButtons/Button.jsx"

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx"

function HeaderLinks({ ...props }) {
  const { classes } = props
  return (
    <List className={classes.list}>
      {/*<ListItem className={classes.listItem}>*/}
      {/*  <CustomDropdown*/}
      {/*    noLiPadding*/}
      {/*    buttonText="Components"*/}
      {/*    buttonProps={{*/}
      {/*      className: classes.navLink,*/}
      {/*      color: "transparent",*/}
      {/*    }}*/}
      {/*    buttonIcon={Apps}*/}
      {/*    dropdownList={[*/}
      {/*      <Link to="/" className={classes.dropdownLink}>*/}
      {/*        All components*/}
      {/*      </Link>,*/}
      {/*      <a*/}
      {/*        href="https://creativetimofficial.github.io/material-kit-react/#/documentation"*/}
      {/*        target="_blank"*/}
      {/*        className={classes.dropdownLink}*/}
      {/*      >*/}
      {/*        Documentation*/}
      {/*      </a>,*/}
      {/*    ]}*/}
      {/*  />*/}
      {/*</ListItem>*/}
      <ListItem className={classes.listItem}>
        <Link to="/#howItWorks" className={classes.navLink}>
          Comment ça marche ?
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/#offers" className={classes.navLink}>
          Nos offres
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/#contact" className={classes.navLink}>
          Contact
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Suivez-nous sur instagram"
          placement={typeof window !== "undefined" && window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/elm.homedesign"
            target="_blank"
            className={classes.navLink}
          >
            <FaInstagram />
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  )
}

export default withStyles(headerLinksStyle)(HeaderLinks)
